import { createApp } from 'vue'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import echarts from './util/echartsUI.js'
import router from './router';
import store from './store';
import App from './App.vue';
import Print from 'vue3-print-nb'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
// import * as Elicons from "@element-plus/icons-vue";

const app = createApp(App)
app.config.globalProperties.$echarts = echarts
app.use(ElementPlus, {
  locale: zhCn,
})
// for (const name in Elicons) {
//   app.component(name, Elicons[name]);
// }
app.use(store).use(router).use(Print).use(ElementPlus).mount('#app')
