<template>
  <div class="appView">
    <!-- 顶部显示 -->
    <div class="topView"
         v-if="this.$route.name!='Login'&&this.$route.name">
      <top-info :no_login="this.$route.name != 'Login'"></top-info>
    </div>
    <!-- 路由渲染 -->
    <div class="routerView"
         :style="this.$route.name!='Login'?'':'top: 0 !important;'">
      <router-view v-slot="{ Component }">
        <transition name="el-fade-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import TopInfo from './components/TopInfo'
export default {
  data () {
    return {
      pagesize: '20',
      page: '1',
    }
  },
  methods: {

  },
  computed: {
    warehouse () {
      return this.$store.state.warehouse
    }
  },
  components: {
    TopInfo
  },
  created () {
    if (this.$route.name != 'Login' && this.$route.name) {
      let data = localStorage.getItem('cklist')
      if (!data) {
        this.$store.dispatch('cklist')
      }
    }

  }
}
</script>
<style lang="scss">
@import "/src/assets/css/bese.css";
* {
  margin: 0;
  padding: 0;
}
.appView {
  width: 100%;
  min-width: 1000px;
  height: 100vh;
  .topView {
    height: 80px;
  }
  .routerView {
    min-width: 1000px;
    min-height: 800px;
    position: absolute;
    width: 100%;
    top: 80px;
    bottom: 0px;
  }
}
</style>
