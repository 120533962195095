<template>
  <!-- 顶部信息 -->
  <div class="topInfo">

    <!-- =========logo信息========= -->
    <div class="company_logo"
         @click="returnIndex">
      <el-image style="width: 60px; height: 60px"
                src="https://www.tuanyun99.com/logo.png"></el-image>
      <!-- =========公司信息========= -->
      <div class="logo_text">
        <span>团运物流系统</span>
        <span>www.tuanyun99.com</span>
      </div>
    </div>

    <!-- =========用户信息========= -->
    <div class="userInfo">
      <div class="tabWarehouse">
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            {{userSelectWarehouse.name}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-for="item in warehouseData?.cklist"
                                :key="item.id"
                                @click="tabWID(item)">{{item.name}}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div class="userAvatar">
        <el-dropdown>
          <el-avatar shape="circle"
                     :size='40'
                     :src="avatarUrl"></el-avatar>
          <span class="userName">{{userName}}</span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item icon="el-icon-delete"
                                @click="exitLogin">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

      </div>
    </div>

  </div>
</template>

<script>
import { postExitLogin } from '@/request/api'
export default {
  name: 'topInfo',
  data () {
    return {
      // ------您选择的仓库-------
      userSelectWarehouse: {
        id: '',
        name: ''
      },
      warehouseData: {

      }
    }
  },
  props: {
    no_login: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    userName () {
      if (this.no_login) {
        let userinfo = localStorage.getItem('tuangyin_ck_userinfo')
        if (userinfo) {
          return JSON.parse(localStorage.getItem('tuangyin_ck_userinfo')).userinfo.realname
        }
      }
      return ''
    },
    avatarUrl () {
      let userinfo = localStorage.getItem('tuangyin_ck_userinfo')
      if (this.no_login && userinfo) {
        return JSON.parse(localStorage.getItem('tuangyin_ck_userinfo')).userinfo.avatar
      }
      return 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'
    },
    // 21472 69462

  },
  methods: {
    //   切换用户选择的仓库id
    tabWID (item) {
      this.warehouseData = item
      localStorage.setItem('ty_warehouseData', JSON.stringify(item))
      this.$router.go(0)
    },
    //   返回首页
    returnIndex () {
      this.$router.push({
        name: 'Index'
      })
    },
    //   退出登录
    async exitLogin () {
      let res = await postExitLogin()
      if (res.errno == 0) {
        localStorage.removeItem('cklist')
        localStorage.removeItem('ty_warehouseData')
        localStorage.removeItem('tuangyin_ck_userinfo')
        this.$message.success(res.message)
        this.$router.push({
          name: 'Login'
        })
      } else {
        this.$message(res.message)
      }
    }
  },
  async mounted () {
    let userinfo = localStorage.getItem('tuangyin_ck_userinfo')
    let ty_warehouseData_c = localStorage.getItem('ty_warehouseData')
    let cklist = JSON.parse(localStorage.getItem('cklist'))

    //  --------- 
    if (!cklist) {
      await this.$store.dispatch('cklist')
      localStorage.setItem('cklist', JSON.stringify(this.$store.state.warehouse))
      cklist = this.$store.state.warehouse
    }

    if (userinfo) {
      let ty_warehouseData = JSON.parse(ty_warehouseData_c)
      this.warehouseData = cklist
      if (this.warehouseData) {
        if (ty_warehouseData) {
          this.userSelectWarehouse = ty_warehouseData
        } else {
          localStorage.setItem('ty_warehouseData', JSON.stringify(this.warehouseData.cklist[0]))
          this.userSelectWarehouse = this.warehouseData.cklist[0]
        }
      } else {
        this.warehouseData = this.$store.state.warehouse
        if (ty_warehouseData) {
          this.userSelectWarehouse = ty_warehouseData
        } else {
          localStorage.setItem('ty_warehouseData', JSON.stringify(this.warehouseData.cklist[0]))
          this.userSelectWarehouse = this.warehouseData.cklist[0]
        }
      }
    } else {
      this.exitLogin()
    }
  }

}
</script>

<style lang="scss">
.topInfo {
  width: 100%;
  height: 100%;
  background-color: #409eff;
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  .company_logo {
    cursor: pointer;
    display: flex;
    height: 100%;
    align-items: center;
    .logo_text {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      color: #fefefd;
      font-size: 16px;
      font-weight: 700;
    }
  }
  .tabWarehouse {
    margin-right: 20px;
    .el-dropdown {
      color: #fefefd !important;
      padding: 10px;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.459);
    }
  }
  .userInfo {
    display: flex;
    align-items: center;
    .userAvatar {
      display: flex;
      align-items: center;
      .el-dropdown {
        display: flex;
        align-items: center;
      }
      .userName {
        font-size: 14px;
        color: #fefefd;
        font-weight: 700;
        letter-spacing: 1px;
        margin-left: 10px;
      }
    }
  }
}
</style>