import { post,get } from './index'

// -------登录接口-------
export const postLogin = p => post('index.php?i=2&c=entry&do=login&m=wxl_wms', p)

// -------退出登录接口-------
export const postExitLogin = p => post('index.php?i=2&c=entry&op=login_out&do=login&m=wxl_wms', p)

// -------获取用户可操作的仓库-------
export const postUserWarehouse = p => post('index.php?i=2&c=entry&do=index&m=wxl_wms&op=list', p)


// -------获取仓位管理数据-------
export const postWarehouseLocation = p => get('index.php?i=2&c=entry&do=gsinfo&m=wxl_wms&op=list', p)

// -------区域管理-------
export const postArea = p => post('index.php?i=2&c=entry&do=gsinfo&m=wxl_wms&op=area', p)

// -------货架管理-------
export const postHJ = p => post('index.php?i=2&c=entry&do=gsinfo&m=wxl_wms&op=hj', p)

// -------层管理-------
export const postTier = p => post('index.php?i=2&c=entry&do=gsinfo&m=wxl_wms&op=nop', p)

// -------格管理-------
export const postRattle = p => post('index.php?i=2&c=entry&do=gsinfo&m=wxl_wms&op=ga', p)


// -------物流单号获取货物-------
export const postWlnumCode = p => post('index.php?i=2&c=entry&do=index&m=wxl_wms&op=searchhjcode', p)

// -------货架号获取货物-------
export const postCodeGetGoods = p => post('index.php?i=2&c=entry&do=gsinfo&m=wxl_wms&op=orderlist', p)

// -------验证货架号-------
export const verificationCode = p => post('index.php?i=2&c=entry&do=index&m=wxl_wms&op=verifyhjcode', p)

// -------获取仓库对应订单-------
export const postOrder = p => post('index.php?i=2&c=entry&do=order&m=wxl_wms', p)

// -------获取仓库打包订单详情-------
export const ckPage = p => post('index.php?i=2&c=entry&do=ck&m=wxl_wms&&op=dabao', p)

// -------获取汇总订单-------
export const postOrder_hz = p => post('index.php?i=2&c=entry&do=order&m=wxl_wms&op=total&pd=hz', p)

// -------出入库数据获取-------
export const posyOutCome = p => post('index.php?i=2&c=entry&do=ck&m=wxl_wms', p)

// -------添加附加费-------
export const postAppAdditional = p => post('index.php?i=2&c=entry&do=ck&m=wxl_wms&op=otherprice&pd=ok', p)

// -------获取仓库成员-------
export const postAccunt = p => post('index.php?i=2&c=entry&do=user&m=wxl_wms&op=display', p)

// -------获取用户信息-------
export const postUserInfo = p => post('index.php?i=2&c=entry&do=mc&m=wxl_wms&op=userinfo', p)

// -------添加用户-------
export const postAddAccunt = p => post('index.php?i=2&c=entry&do=user&m=wxl_wms&op=post', p)

// -------删除用户-------
export const postRemoveUser = p => post('index.php?i=2&c=entry&do=user&m=wxl_wms&op=delt', p)

// -------获取订单详情-------
export const postOrderDetails = p => post('index.php?i=2&c=entry&do=order&m=wxl_wms', p)

// -------获取物品类型-------
export const postGoodsType = p => post('index.php?i=2&c=entry&m=wxl_wms&do=wptype', p)

// -------重新核验-------
export const postInspect = p => post('index.php?i=2&c=entry&do=ck&m=wxl_wms&op=gxbaoguo', p)

// -------获取柜子信息-------
export const getLclist = p => post('index.php?i=2&c=entry&do=index&m=wxl_wms&op=lclist', p)


// -------仓库管理【删除】-------
export const delWarehouse = p => post('index.php?i=2&c=entry&do=ckadmin&m=wxl_wms&op=del', p)

// -------入库物品类型-------
export const rkWptype = p => post('index.php?i=2&c=entry&m=wxl_wms&do=wptype', p)

// -------保存凭证-------
export const saverCredential= p => post('index.php?i=2&c=entry&m=wxl_wms&do=ck&op=package_credential', p)

