import { createStore } from 'vuex'
import { postUserWarehouse } from '@/request/api'

export default createStore({
  state: {
    // ------------仓库列表------------
    warehouse: '',
    // ------------数量单位------------
    numberUnit: ["个", "台", "套", "双", "件", "只", "支", "把", "本", "片", "条"],
    // ------------货币类型------------
    currenctyDate: [{
      label: '人民币',
      value: 'CNY'
    }, {
      label: '美元',
      value: '美元'
    }, {
      label: '越南盾',
      value: 'VND'
    }, {
      label: '新加坡元',
      value: 'MOP'
    }, {
      label: '日元',
      value: 'JPY'
    }, {
      label: '韩元',
      value: 'JPY'
    }, {
      label: '马币',
      value: 'JPY'
    }, {
      label: '日元',
      value: 'MYR'
    }, {
      label: '台币',
      value: 'NTD'
    }, {
      label: '港币',
      value: 'HKD'
    }, {
      label: '欧元',
      value: 'EUR'
    }, {
      label: '英镑',
      value: 'GBP'
    }, {
      label: '法郎',
      value: 'FRF'
    }, {
      label: '印度卢比',
      value: 'INR'
    }, {
      label: '俄罗斯卢布',
      value: 'RUB'
    }, {
      label: '澳大利亚元',
      value: 'AUD'
    }, {
      label: '荷兰盾',
      value: 'NLG'
    }],



  },
  mutations: {
    // ------修改仓库列表------
    changeWarehouse (state, res) {
      state.warehouse = res
    }

  },
  actions: {
    // ------获取默认仓库数据------
    async cklist ({ commit }) {
      let res = await postUserWarehouse({
        pagesize: this.pagesize,
        page: this.page
      })
      if (res.errno == 0) {
        commit('changeWarehouse', res)
        localStorage.setItem('cklist', JSON.stringify(res))
      }
    }
  },
  modules: {
  }
})
