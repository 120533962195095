import { createRouter, createWebHashHistory } from 'vue-router'
// createWebHistory

const routes = [
  // -----------默认跳转首页-----------
  {
    path: '/',
    redirect: '/index'
  }, {
    //-----------首页-----------
    path: '/index',
    name: 'Index',
    component: () => import('../views/Index.vue')
  }, {
    //-----------登录-----------
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  }, {
    //-----------出入库-----------
    path: '/ComeOut',
    name: 'ComeOut',
    component: () => import('../views/ComeOut.vue')
  }, {
    //-----------打包订单浏览-----------
    path: '/Pack',
    name: 'Pack',
    component: () => import('../views/Pack/Pack.vue')
  }, {
    //-----------开始打包-----------
    path: '/start_pack',
    name: 'start_pack',
    component: () => import('../views/Pack/Start_pack.vue')
  }, {
    //-----------仓位管理-----------
    path: '/positions',
    name: 'positions',
    component: () => import('../views/Positions/positions.vue')
  }, {
    //-----------仓库订单列表-----------
    path: '/order',
    name: 'order',
    component: () => import('../views/order.vue')
  }, {
    //-----------账号管理-----------
    path: '/accountM',
    name: 'accountM',
    component: () => import('../views/accountM.vue')
  }, {
    //-----------打印-----------
    path: '/printView',
    name: 'printView',
    component: () => import('../views/print/printView.vue')
  }, {
    //-----------核验-----------
    path: '/inspect',
    name: 'inspect',
    component: () => import('../views/inspect/inspect.vue')
  }, {
    //-----------测试-----------
    path: '/concentratedRK',
    name: 'concentratedRK',
    component: () => import('../views/concentratedRK.vue')
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
