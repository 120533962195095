import axios from "axios"
import QS from 'qs';
import router from '@/router'



// ---------运行环境检测---------
if (process.env.NODE_ENV == 'production') {
  axios.defaults.baseURL = 'https://www.tuanyun99.com/wxoms/app/';  //服务器
} else if (process.env.NODE_ENV == 'development') {
  axios.defaults.baseURL = '/api';  //本地
}

// ---------设置超时---------
axios.defaults.withCredentials = true
axios.defaults.timeout = 10000;

// ---------post请求头---------
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.headers.post['X-Requested-With'] = 'xmlhttprequest';

// ---------请求拦截器---------
axios.interceptors.request.use(
  config => {
    let XTOKEN = ''
    const userinfo = localStorage.getItem('tuangyin_ck_userinfo')
    if (userinfo) {
      XTOKEN = JSON.parse(userinfo).token
    }
    config.headers.XTOKIN = XTOKEN

    return config;
  },
)

// ---------响应拦截器---------
axios.interceptors.response.use(
  response => {
    // 如果检测未登入
    if (response.data.errno == 5001) {
      alert(response.data.message)
      return router.replace({
        path: '/login',
        query: { redirect: router.currentRoute.fullPath }
      });
    }
    //   正常返回
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是200的情况    
  error => {
    if (error.response.status) {

      return Promise.reject(error.response);
    }
  }
);

// ---------抛出get方法---------
export function get (url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data)
      })
  });
}

// ---------抛出post方法 注意序列化---------
export function post (url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, QS.stringify(params))
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data)
      })
  });
}
// (index_3+1) %2==0?'odd_btn':''